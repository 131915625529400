<template lang="pug">
.flex.flex-col.w-full
  .w-full.max-w-5xl.mx-auto.mb-24
    .container
      .section-header
        XyzTransition(xyz="fade up-1", appear)
          .header Comprobando el pago
        XyzTransition(xyz="fade up-1 delay-1", appear)
          .subtitle(v-if="!isSuccess") la comprobación puede tardar algunos segundos...
      .section-cv.flex.flex-col.gap-16
        XyzTransition(xyz="fade delay-1 up-1", appear)
          .loader(v-if="!isSuccess")
            .animate-spin.text-blue-400
              BaseCustomIcon(nameIcon="mdiDotsCircle", :size="48")
        XyzTransition(xyz="fade delay-1 up-1 delay-8", appear)
          .flex.justify-center(v-if="isSuccess")
            .max-w-xl.flex-grow-1
              CardAlert(title="Pago completado con exito")
footerComponent
</template>

<script>
import footerComponent from "@/components/structure/navFooter/navFooter.vue";
import AccountCreateComponent from "@/components/www/builder/payment/account-created-component.vue";
import { UsersRepository } from "@/repository/users.repository";

import CardAlert from "@/components/molecules/cardAlert.vue";

export default {
  name: "step-3-payment",
  components: {
    footerComponent,
    AccountCreateComponent,
    CardAlert,
  },
  props: {
    payment_id: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    isSuccess: false,
  }),
  created() {
    console.log(this.payment_id);
    UsersRepository.verificationPayment(this.payment_id)
      .then((result) => {
        console.log(result);
        this.verificationPayment(result.payment_status);
      })
      .catch(({ response }) => {
        this.verificationPayment(response.data.message);
      });
  },
  computed: {},
  methods: {
    back() {
      return this.$router.push({ name: "builder/creator" });
    },
    verificationPayment(result) {
      if (result == "succeeded") {
        this.isSuccess = true;
        setTimeout(() => this.$router.push({ name: "my-cvs" }), 2500);
      } else if (result == "error") {
        this.$store.commit("WebOptionsStore/ActiveErrorPayment");
        return this.$router.replace({ name: "builder/payment" });
      } else if (result == "Unauthorized") {
        return this.$router.replace({ name: "Home" });
      } else if (result == "payment-not-match-user") {
        return this.$router.replace({ name: "Home" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.section-header {
  @apply flex justify-center flex-col text-center items-center mt-20 mb-12;
  .header {
    @apply mb-6 text-4xl font-black text-gray-600 text-center;
  }
  .subtitle {
    @apply text-lg text-blue-400;
  }
}
.section-cv {
  @apply flex w-full;
}

.button-back {
  @apply flex items-center text-lg pr-12 pl-6 py-4 text-center bg-gray-900 text-white rounded-lg font-medium cursor-pointer;
}

.loader {
  @apply flex items-center justify-center h-full w-full;
}
</style>
